<template>
  <div class="columns">
    <div class="column is-one-third">
      <card-component
        title="1. Run PLS"
        class="tile is-child"
        :displayHelp="$options.name"
      >
        <div class="has-text-centered">
          <b-button rounded
            class="is-primary button-shadow"
            @click.prevent="run()"
          >
            <span class="icon is-small"><i class="fa fa-cogs"></i></span>
            <span> Run </span>
          </b-button>
        </div>
      </card-component>
    </div>
    <div class="column">
      <card-component title="2. Plots" />
      <error :type='"notifications"' />
      <card-no-result v-if="graphicals.length===0">
        <strong>How to set options?<br></strong>
        Now that your datasets are ready to be used, click on the left panel to
        start PLS and wait for the computation (that can be a bit long).
        <br><br>
        <strong>Important note</strong>: PLS is performed with raw values (data
        are not scaled to unit variance before they are processed). <strong>If
        you want to perform PLS on scaled data</strong> (for one or both
        datasets), go to the menu “Edit / Dataset edition” and scale your
        dataset(s) before PLS.
      </card-no-result>
      <card-component v-else>
        <plotly-list
          :data="graphicals"
        ></plotly-list>
      </card-component>
    </div>
  </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'

export default {
  name: 'RPLSStep',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'error': () => import('@/components/ui/Error.vue')
  },
  props: {
    dataset: {
      type: String,
      default: null
    }
  },
  mixins: [ranalysis],
  data () {
    return {
      datasetName: null,
      ncp: null,
      currentResult: ''
    }
  },
  watch: {
    dataset: function () {
      this.datasetName = this.dataset
    }
  },
  computed: {
    params: function () {
      return {
        'func_name': 'r_pls',
        'datasetName': this.datasetName,
        'out_graph': true
      }
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    afterRun: function (data) {
      this.$emit('is-computed',
        { 'plsObj': data.ObjectName,
          'plsMode': data.Mode })
    }
  }
}
</script>

<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
